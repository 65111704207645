<template>
  <div class="thumb-chip py-1 px-2">
    <div class="content d-flex align-center">
      <div class="part like">
        <v-icon x-small color="white" class="mr-2">mdi-heart-outline</v-icon>
        <span class="text-caption text-truncate white--text mr-3">{{ like }}</span>
      </div>
      <div class="part thumb">
        <v-icon x-small color="white" class="mr-2">mdi-eye-outline</v-icon>
        <span class="text-caption text-truncate white--text">{{ thumb }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    like: {
      type: Number,
      default: 0
    },
    thumb: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.thumb-chip {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  width: fit-content;
  backdrop-filter: blur(2px);
}
</style>
