<template>
  <v-hover v-slot="{ hover: hoverCard }">
    <!-- hover 时加深阴影 -->
    <v-card
      class="card"
      elevation="0"
      :class="hoverCard && 'card-shadow-hover'"
      :to="toLink">
      <!-- 预览图 -->
      <v-hover v-slot="{ hover: hoverImage }">
        <v-card-text class="pb-0">
          <!-- 新内容角标 -->
          <div class="badge" v-if="hasUpdate" :class="{ hover: hoverCard }"></div>
          <v-img
            :aspect-ratio="21/9"
            class="indigo lighten-4"
            no-gradient="to top, rgba(0, 0, 0, 0.3) 0%, transparent 72px"
            :src="article.image"
            :position="position"
            :contain="!isCover"
          >
            <!-- 图片加载 Loading -->
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <!-- 图片右下角的浏览量 -->
            <ThumbChips
              class="thumb-chips"
              :like="(article.statistics || {}).likeNumebr"
              :thumb="(article.statistics || {}).viewNumber"
            ></ThumbChips>
            <!-- 日期板 -->
            <v-expand-transition>
              <div
                v-if="hoverImage"
                class="d-flex transition-fast-in-fast-out indigo accent-1 v-card--reveal text-h4 white--text"
                style="height: 100%;"
              >
                {{ createTime }}
              </div>
            </v-expand-transition>
          </v-img>
        </v-card-text>
      </v-hover>
      <v-card-text class="py-3">
        <div class="d-flex justify-start align-center">
          <!-- 类型 -->
          <v-chip v-for="tag in article.tagEntities || []"
            :key="tag.id"
            class="mr-2 white--text"
            :color="tag.color"
            label
            small
          >{{ tag.name }}</v-chip>
        </div>
      </v-card-text>
      <!-- 标题 -->
      <v-card-title class="pt-0 indigo--text text--lighten-1 font-weight-bold">{{ article.name }}</v-card-title>
      <v-card-text>
        <!-- 描述 -->
        <div class="describe mb-4">{{ article.describe }}</div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import ThumbChips from '@/components/ThumbChips.vue'
import { getUrlParamMap } from '@/utils'
import dayjs from 'dayjs/esm'
import { mapState } from 'vuex'

export default {
  components: {
    ThumbChips
  },
  props: {
    article: {
      required: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    ...mapState('app', ['editTimeCollection']),
    hasUpdate () {
      const cacheTime = this.editTimeCollection[this.article.id]
      if (!cacheTime) {
        return false
      }
      return this.article.updateTime > cacheTime
    },
    toLink () {
      return {
        name: 'Article',
        params: {
          id: this.article.id
        }
      }
    },
    // 不用 isContain 的原因是适配现有的图片，现有图片多是 contain
    isCover () {
      return !!this.imageParamMap.get('cover')
    },
    position () {
      return this.imageParamMap.get('position') || 'center center'
    },
    // 图片链接参数解析
    imageParamMap () {
      return getUrlParamMap(this.article.image || '')
    },
    createTime () {
      return dayjs(this.article.createTime).locale('zh-cn').format('MMMM D, YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: #f6f7ff;
  transition: all 0.2s ease-in;
  cursor: pointer;
  height: 390px;
  border: thin solid rgba(0, 0, 0, 0);
}

.card-shadow-hover {
  // box-shadow: 0 3px 15px 5px rgb(219, 221, 255),0 3px 3px -2px hsla(0,0%,69.8%,.10196078431372549),0 1px 8px 0 hsla(0,0%,60.4%,.10196078431372549) !important;
  background: white;
  border: thin solid rgba(0, 0, 0, 0.12);
}

.numbers {
  width: 50%;
}

.describe {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  box-orient: vertical;
  -webkit-box-orient: vertical;
}

.thumb-chips {
  position: absolute;
  bottom: 0;
  right: 0;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  // opacity: .5;
  position: absolute;
  width: 100%;
}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #E91E63;
  box-shadow: 0 0 0 1px white;
  z-index: 1;
  transition: all 0.2s cubic-bezier(.24,1.2,.72,1.53);
  &.hover {
    top: -6px;
    right: -6px;
  }
}
</style>
