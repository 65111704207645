<template>
  <v-container class="white mb-4 mt-6 rounded card">
    <v-row>
      <v-col class="pb-0">
        <div class="px-3">
          <!-- 发送评论 -->
          <v-text-field
            v-model="comment"
            @click:append="onSubmit"
            @keyup.enter="onSubmit"
            class="mt-4"
            label="发送评论"
            append-icon="mdi-send"
            hide-details
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- 评论列表 -->
        <v-list three-line>
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
          <empty v-else-if="isEmpty" tips="没有更多评论啦~"></empty>
          <template v-else v-for="(comment, index) in comments">
            <v-list-item
              :key="'comment_' + comment.id"
            >
              <v-list-item-avatar>
                <v-img :src="comment.user.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-subtitle-1 mr-4">{{ comment.user.name }}</span>
                  <span class="text-caption grey--text text--lighten-1">{{ comment.createdAt }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ comment.content }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < comments.length - 1"
              :key="'divider' + comment.id"
              inset>
            </v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-model="pager.pageIndex"
          :length="pager.totalPages || 1"
          :total-visible="0"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs/esm'
import { addComment, getComments } from 'apis/topic'

const getDefaultPager = () => ({
  pageIndex: 1,
  pageSize: 5
})

export default {
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      comment: '',
      isLoading: false,
      isEmpty: false,
      comments: [],
      pager: getDefaultPager()
    }
  },
  watch: {
    'pager.pageIndex': {
      immediate: true,
      handler () {
        this.loadMoreComments()
      }
    }
  },
  methods: {
    loadMoreComments (refresh = false) {
      if (refresh) {
        this.pager = getDefaultPager()
      }
      this.isLoading = true
      getComments(this.type)({
        pageIndex: this.pager.pageIndex,
        pageSize: this.pager.pageSize
      }).then(({ data: list, pager }) => {
        this.pager.totalPages = pager.totalPages
        this.isEmpty = pager.empty
        // 处理日期
        this.comments = (list || []).map(comment => {
          comment.createdAt = this.formatTime(comment.createdAt)
          return comment
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    onSubmit () {
      if (!this.comment || !this.comment.trim()) {
        this.$noti.error('评论不能为空')
        return
      }
      // 弹框输入验证码
      this.$auth((code, { setLoading, setClose }) => {
        setLoading(true)
        addComment(code)({
          type: this.type,
          content: this.comment
        }).then(() => {
          this.comment = ''
          // 刷新评论列表
          this.loadMoreComments(true)
          this.pager = getDefaultPager()
          setClose()
        }).catch(err => {
          this.$noti.error(err.message)
        }).finally(() => {
          setLoading(false)
        })
      })
    },
    formatTime (mills) {
      return dayjs(mills).format('YYYY.MM.DD HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
