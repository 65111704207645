<template>
  <div class="text-center" ref="area">
    <template v-if="loading">
      <v-progress-circular
        indeterminate
        :color="color"
      ></v-progress-circular>
      <span class="text-caption ml-4 grey--text text--darken-1">{{ loadingTips }}</span>
    </template>
    <template v-else-if="end">
      <span class="text-caption grey--text text--darken-1">{{ endTips }}</span>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    end: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    loadingTips: {
      type: String,
      default: 'Loading'
    },
    endTips: {
      type: String,
      default: 'End'
    },
    root: {
      type: String,
      default: ''
    },
    rootMargin: {
      type: String,
      default: '0px'
    },
    threshold: {
      type: Number,
      default: 1.0
    }
  },
  mounted () {
    this.$emit('refresh')
    const root = this.root ? document.querySelector(this.root) : document
    const options = {
      root,
      rootMargin: this.rootMargin,
      threshold: this.threshold
    }
    const observer = new IntersectionObserver(this.onShow, options)
    this.observer = observer
    const area = this.$refs.area
    observer.observe(area)
  },
  beforeDestroy () {
    if (!this.observer) {
      return
    }
    const area = this.$refs.area
    this.observer.unobserve(area)
    this.observer.disconnect()
  },
  methods: {
    onShow (entries) {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          if (this.loading || this.end) {
            return
          }
          this.$emit('refresh')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
