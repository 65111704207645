import { Get, Post } from './index'

// 获取 WorldBox Mod 作者列表
export const getWorldboxMods = Get('/plate/mods/10')
// 获取 HH Mod 作者列表
export const getHHMods = Get('/plate/mods/11')
// 添加留言
export const addComment = code => Post(`/plate/board?code=${code}`)
// 获取留言
export const getComments = type => Get(`/plate/board/${type}`)
// 获取 HH 留言
export const getHHComments = Post('/plate/board/1')
// 获取 WorldBox 留言
export const getWorldboxComments = Post('/plate/board/0')
